import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
} from '@mui/material';
import React from 'react';
import { ReactComponent as IconChevronDown } from '../../../assets/icn-chevron-down.svg';
import { dusk, heather, white } from 'src/constants/colors';
import { useWalletChainEditContext } from './context/edit/WalletChainEditContext';
import IconSolana from '../../../assets/chains/icn-sol.png';
import IconAgoric from '../../../assets/chains/icn-agoric.png';
import IconCelestia from '../../../assets/chains/icn-celestia.png';
import IconPersisitense from '../../../assets/chains/icn-pers.png';
import IconOrai from '../../../assets/chains/icn-orai.png';
import IconKava from '../../../assets/chains/icn-kava.png';
import IconDymension from '../../../assets/chains/icn-dymension.png';
import IconDydx from '../../../assets/chains/icn-dydx.png';
import IconCosmos from '../../../assets/chains/icn-cosmos.png';
import IconEthereum from '../../../assets/chains/icn-eth.png';
import IconAvalanche from '../../../assets/chains/icn-avax.png';
import IconBnb from '../../../assets/chains/icn-bnb.png';
import IconAptos from '../../../assets/chains/icn-aptos.png';
import IconArbitrum from '../../../assets/chains/icn-arb.png';
import IconBase from '../../../assets/icn-base.svg';
import IconInjective from '../../../assets/chains/icn-injective.png';
import IconNear from '../../../assets/chains/icn-near.png';
import IconOptimism from '../../../assets/chains/icn-op.png';
import IconOsmosis from '../../../assets/chains/icn-osmosis.png';
import IconPolygon from '../../../assets/chains/icn-polygon.png';
import IconSui from '../../../assets/chains/icn-sui.png';
import IconZkSync from '../../../assets/chains/icn-zksync.png';
import IconNibiru from '../../../assets/chains/icn-nibiru.png';
import IconAcala from '../../../assets/chains/icn-acala.png';
import IconEvmos from '../../../assets/chains/icn-evmos.png';
import Iconxion from '../../../assets/xion.png';
import IconBlast from '../../../assets/blast.jpg';
import IconCelo from '../../../assets/celo.png';
import IconLinea from '../../../assets/linea.jpg';
import IconManta from '../../../assets/manta.jpg';
import IconMantle from '../../../assets/man.jpg';
import IconMonad from '../../../assets/MONAD.jpg';
import IconScroll from '../../../assets/SCROLL.jpg';
import IconELYS from '../../../assets/ELYS.png';
import IconNeutron from '../../../assets/NEUTRON.jpg';
import IconArchway from '../../../assets/ARCHWAY.jpg';
import IconMovement from '../../../assets/MOVEMENT.png';
import IconBera from '../../../assets/BERA.jpg';
import IconAxelar from '../../../assets/Axelar.png';
import IconRootNetwork from '../../../assets/chains/icn-trn.png';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';

export const chains = [
  {
    name: 'Acala',
    image: IconAcala,
  },
  {
    name: 'Agoric',
    image: IconAgoric,
  },
  {
    name: 'Aptos',
    image: IconAptos,
  },
  {
    name: 'Arbitrum',
    image: IconArbitrum,
  },
  {
    name: 'Axelar',
    image: IconAxelar,
  },
  {
    name: 'Archway',
    image: IconArchway,
  },
  {
    name: 'Avalanche',
    image: IconAvalanche,
  },
  {
    name: 'Base',
    image: IconBase,
  },
  {
    name: 'Bera',
    image: IconBera,
  },
  {
    name: 'Blast',
    image: IconBlast,
  },
  {
    name: 'BINANCE',
    image: IconBnb,
  },
  {
    name: 'Celestia',
    image: IconCelestia,
  },
  {
    name: 'Celo',
    image: IconCelo,
  },
  {
    name: 'Cosmos',
    image: IconCosmos,
  },
  {
    name: 'DYDX',
    image: IconDydx,
  },
  {
    name: 'Dymension',
    image: IconDymension,
  },
  {
    name: 'Ethereum',
    image: IconEthereum,
  },
  {
    name: 'EVMos',
    image: IconEvmos,
  },
  {
    name: 'ELYS',
    image: IconELYS,
  },
  {
    name: 'Injective',
    image: IconInjective,
  },
  {
    name: 'Kava',
    image: IconKava,
  },
  {
    name: 'Linea',
    image: IconLinea,
  },
  {
    name: 'Manta',
    image: IconManta,
  },
  {
    name: 'Mantle',
    image: IconMantle,
  },
  {
    name: 'Monad',
    image: IconMonad,
  },
  {
    name: 'Movement',
    image: IconMovement,
  },
  {
    name: 'Near',
    image: IconNear,
  },
  {
    name: 'Neutron',
    image: IconNeutron,
  },
  {
    name: 'Nibiru',
    image: IconNibiru,
  },
  {
    name: 'Optimism',
    image: IconOptimism,
  },
  {
    name: 'Orai',
    image: IconOrai,
  },
  {
    name: 'Osmosis',
    image: IconOsmosis,
  },
  {
    name: 'Persistence',
    image: IconPersisitense,
  },
  {
    name: 'Polygon',
    image: IconPolygon,
  },
  {
    name: 'Scroll',
    image: IconScroll,
  },
  {
    name: 'Solana',
    image: IconSolana,
  },
  {
    name: 'Sui',
    image: IconSui,
  },
  {
    name: 'Xion',
    image: Iconxion,
  },
  {
    name: 'zkSync',
    image: IconZkSync,
  },
  {
    name: 'The Root Network',
    image: IconRootNetwork,
  },
] as const;

type Props = Readonly<{
  sx?: SxProps;
  label?: string;
}>;

const CardConfigSelectChain: React.FC<Props> = ({
  sx,
  label = 'Select supported wallet connect chain',
}) => {
  const { isAP2Enabled } = useFeatureFlag();
  const {
    state: { editState },
    dispatch,
  } = useWalletChainEditContext();

  return (
    <FormControl fullWidth sx={sx}>
      <InputLabel id="chain-label">{label}</InputLabel>
      <Select
        labelId="chain-label"
        id="chain-select"
        label={label}
        value={editState}
        onChange={(e) => {
          dispatch({
            type: 'SetWalletChainAction',
            value: e.target.value,
          });
        }}
        MenuProps={{
          sx: {
            '& .MuiPaper-root': {
              maxHeight: 400,
              filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05))',
            },
            '& .MuiPaper-root::-webkit-scrollbar': {
              width: '4px',
            },
            '& .MuiPaper-root::-webkit-scrollbar-thumb': {
              backgroundColor: heather,
              borderRadius: '30px',
            },
          },
        }}
        IconComponent={(props) => {
          return (
            <IconChevronDown
              {...props}
              style={{
                marginRight: '12px',
                top: 'calc(50% - .7em)',
                fill: isAP2Enabled ? dusk : white,
              }}
            />
          );
        }}
        renderValue={(selected) => {
          if (selected) {
            return selected;
          } else {
            return 'Select supported wallet connect chain';
          }
        }}
      >
        {chains.map((chain, key) => (
          <MenuItem
            key={key}
            value={chain.name}
            sx={{
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              gap: '14px',
            }}
            disabled={chain.name === 'Bera'}
          >
            <img src={chain.image} alt={chain.name} width={22} height={22} />
            {chain.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CardConfigSelectChain;
