import { PreviewButton } from '@components/buttons/PreviewButton';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import ContentEditor from '@components/content-editor';
import { Divider, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  cloud,
  dusk,
  eggshell,
  midnight,
  midnightDarker,
} from 'src/constants/colors';
import { isEmptyContent } from 'src/pages/Community/CreateMessageForm';
import { MessageVariables } from 'src/pages/Community/MessageService';
import {
  TemplateType,
  WalletBlockchain,
  useFusionPreviewCommunityManagerContentLazyQuery,
  usePreviewCommunityManagerContentLazyQuery,
} from 'src/services/gql/generated';
import PreviewDialog from 'src/pages/Community/PreviewDialog';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import { handleCommonPreviewUi } from 'src/util/helper';

type TelegramComposerProps = Readonly<{
  telegramMessageContent: string;
  setTelegramMessageContent: (SMSMessageContent: string) => void;
  selectedTopicKey?: string;
  isDefineTemplate?: boolean;
  isCommunityManager: boolean;
}>;

export const TelegramComposer: React.FC<TelegramComposerProps> = ({
  telegramMessageContent,
  setTelegramMessageContent,
  selectedTopicKey,
  isDefineTemplate,
  isCommunityManager,
}) => {
  const { isAP2Enabled } = useFeatureFlag();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
  const [telegramTemplate, setTelegramTemplate] = useState<any>();
  const [getContent] = usePreviewCommunityManagerContentLazyQuery();
  const [getFusionPreviewContent] =
    useFusionPreviewCommunityManagerContentLazyQuery();
  const { setMessageBarState, clearMessageBarState } =
    useFormMessageBarContext();
  const setErrorMessage = (value: string) => {
    setMessageBarState({ status: 'error', message: value });
  };

  const timestamp = Math.round(Date.now() / 1000);

  const isEnabled =
    !isEmptyContent(telegramMessageContent) && selectedTopicKey !== '';

  const handleClosePreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  const handlePreview = async () => {
    clearMessageBarState();
    const variables: MessageVariables[] = [
      {
        key: 'message',
        value: telegramMessageContent,
      },
    ];
    const queryVariables = {
      previewInput: {
        previewTarget: TemplateType.TELEGRAM,
        sourceAddress: selectedTopicKey ?? '',
        timestamp,
        variables,
        walletBlockchain: WalletBlockchain.OFF_CHAIN,
      },
    };
    const { data, error, loading } = await getContent({
      variables: queryVariables,
    });
    if (!loading && data) {
      setIsPreviewModalOpen(true);
      const { previewCommunityManagerContent } = data;
      setTelegramTemplate(previewCommunityManagerContent.content);
    } else if (error) {
      setErrorMessage('There was an error generating a preview. Try again!');
    }
  };

  const handleFusionPreview = async () => {
    clearMessageBarState();
    const variables: MessageVariables[] = [
      {
        key: 'message',
        value: telegramMessageContent,
      },
    ];
    const queryVariables = {
      fusionCommunityManagerPreviewInput: {
        previewTarget: TemplateType.TELEGRAM,
        targetTemplateId: selectedTopicKey ?? '',
        variables,
      },
    };
    const { data, error, loading } = await getFusionPreviewContent({
      variables: queryVariables,
    });
    if (!loading && data) {
      setIsPreviewModalOpen(true);
      const { fusionPreviewCommunityManagerContent } = data;

      setTelegramTemplate(
        handleCommonPreviewUi(fusionPreviewCommunityManagerContent.content),
      );
    } else if (error) {
      setErrorMessage('There was an error generating a preview. Try again!');
    }
  };
  return (
    <>
      <Stack>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={2}
        >
          <Typography
            sx={{
              fontFamily: 'Rota',
              fontSize: '18px',
              fontWeight: 700,
              lineHeight: '18px',
            }}
          >
            Telegram
          </Typography>

          {isDefineTemplate ? null : (
            <PreviewButton
              handlePreview={isAP2Enabled ? handleFusionPreview : handlePreview}
              disabled={!isEnabled}
              customSx={{ margin: 0 }}
            />
          )}
        </Stack>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={1}
        >
          <Typography
            sx={{
              color: dusk,
              fontFamily: 'Rota',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
            }}
          >
            {isCommunityManager ? 'Content' : 'HTML Content'}
          </Typography>

          <Typography
            sx={{
              color: dusk,
              fontFamily: 'Rota',
              fontSize: '12px',
              fontWeight: 800,
              lineHeight: '18px',
            }}
          >
            max 3.5k characters
          </Typography>
        </Stack>
      </Stack>
      {isCommunityManager ? (
        <ContentEditor
          setValue={setTelegramMessageContent}
          value={telegramMessageContent}
          destination="telegram"
          isCommunityManager={isCommunityManager}
          maxLimit={3500}
        />
      ) : (
        <TextField
          color="secondary"
          disabled={false}
          fullWidth
          hiddenLabel
          id="standard-multiline-static"
          inputProps={{
            py: 1.5,
          }}
          multiline
          onChange={(e) => {
            setTelegramMessageContent(e.target.value);
          }}
          placeholder="Insert your HTML content"
          minRows={5}
          size="small"
          sx={{
            '& fieldset': { borderRadius: '4px' },
            '& textarea': {
              fontFamily: 'Rota',
              fontWeight: 600,
              fontSize: '16px',
            },
            ...(isAP2Enabled
              ? {
                  '& .MuiInputBase-root': {
                    color: midnight,
                  },
                  mb: 3,
                  backgroundColor: eggshell,
                }
              : { mb: 3, backgroundColor: midnightDarker }),
          }}
          value={telegramMessageContent}
          variant="outlined"
        />
      )}
      {isDefineTemplate ? null : (
        <Divider
          sx={{
            borderColor: isAP2Enabled ? cloud : 'rgba(255, 255, 255, 0.12)',
            borderWidth: isAP2Enabled ? '1.5px' : '1px',
          }}
        />
      )}
      <PreviewDialog
        destination="telegram"
        template={telegramTemplate}
        handleClose={handleClosePreviewModal}
        open={isPreviewModalOpen}
        isInsertHtmlContent={isAP2Enabled ? false : true}
      />
    </>
  );
};
