import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { InputField } from '@components/InputField';
import {
  dusk,
  heather,
  midnight,
  newBlueglass,
  white,
} from 'src/constants/colors';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { ReactComponent as ArrowDown } from '../../assets/icn-chevron-down.svg';

type UtcData = {
  hours: string;
  minutes: string;
};

type DateProps = {
  startingDate?: string;
  selectedDate?: string;
  onChange?: (dateTime: string) => void;
  onSubmit?: (dateTime: string) => void;
};

const CustomDatePicker: React.FC<DateProps> = ({
  startingDate = '',
  selectedDate = '',
  onChange,
  onSubmit,
}) => {
  const isSelected = selectedDate !== '';
  const time = new Date();
  const disabledIcon = '#c3c3c3';
  const apiTime = isSelected
    ? selectedDate?.slice(
        selectedDate?.indexOf('T') + 1,
        selectedDate?.indexOf('.'),
      )
    : '';

  const dateParam = isSelected ? new Date(selectedDate) : time;
  const [date, setDate] = useState<string>(formatDate(dateParam));
  const [utcData, setUtcData] = useState<UtcData>({
    hours: isSelected
      ? apiTime?.slice(0, 2)
      : time.getHours()?.toString()?.padStart(2, '0'),
    minutes: isSelected
      ? apiTime?.slice(3, 5)
      : time.getMinutes()?.toString()?.padStart(2, '0'),
  });
  const [btnDisable, setBtnDisable] = useState({
    hoursIncrease: false,
    hoursDecrease: false,
    minutesIncrease: false,
    minutesDecrease: false,
  });

  const btnStyles = {
    p: 0,
    minWidth: '20px !important',
    backgroundColor: 'transparent',
    height: '20px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: 'red !important',
    },
  };

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function getCurrentAndFutureDate() {
    const currentDate =
      startingDate === '' ? new Date(Date.now()) : new Date(startingDate);
    const futureDate = new Date(currentDate);
    futureDate.setMonth(futureDate.getMonth() + 3);
    return {
      currentDate: formatDate(currentDate),
      futureDate: formatDate(futureDate),
    };
  }

  const dates = getCurrentAndFutureDate();

  const handleDateChange = (data: Dayjs | null) => {
    if (data) {
      const formattedDate = data.format('YYYY-MM-DD');
      setDate(formattedDate);
      if (onChange) {
        onChange(`${formattedDate}T${utcData.hours}:${utcData.minutes}:00`);
      }
    } else {
      setDate('');
    }
  };

  const handleUpdate = (key: keyof UtcData, method: string) => {
    setUtcData((prev) => {
      const currentValue = parseInt(prev[key]) || 0;
      let newValue = currentValue;

      if (method === 'increment') {
        if (key === 'hours') {
          newValue = currentValue === 23 ? 0 : currentValue + 1;
        } else if (key === 'minutes') {
          if (currentValue === 59) {
            newValue = 0;
            return {
              ...prev,
              minutes: '00',
              hours: ((parseInt(prev.hours) + 1) % 24)
                .toString()
                ?.padStart(2, '0'),
            };
          } else {
            newValue = currentValue + 1;
          }
        }
      } else if (method === 'decrement') {
        if (key === 'hours') {
          newValue = currentValue === 0 ? 23 : currentValue - 1;
        } else if (key === 'minutes') {
          if (currentValue === 0) {
            newValue = 59;
            return {
              ...prev,
              minutes: '59',
              hours: ((parseInt(prev.hours) - 1 + 24) % 24)
                .toString()
                ?.padStart(2, '0'),
            };
          } else {
            newValue = currentValue - 1;
          }
        }
      }

      const updatedUtcData = {
        ...prev,
        [key]: newValue.toString()?.padStart(2, '0'),
      };

      if (onChange) {
        onChange(
          `${date}T${updatedUtcData.hours}:${updatedUtcData.minutes}:00`,
        );
      }

      return updatedUtcData;
    });
  };

  useEffect(() => {
    const currentMinutes = time.getMinutes();
    const currentHours = time.getHours();

    setBtnDisable(() => {
      const isSameDate = date === dates?.currentDate;
      const isFutureDate = date === dates?.futureDate;

      return {
        hoursDecrease: isSameDate && parseInt(utcData.hours) <= currentHours,
        hoursIncrease: isFutureDate && parseInt(utcData.hours) >= currentHours,
        minutesDecrease:
          isSameDate &&
          parseInt(utcData.hours) === currentHours &&
          parseInt(utcData.minutes) <= currentMinutes,
        minutesIncrease:
          isFutureDate &&
          parseInt(utcData.hours) === currentHours &&
          parseInt(utcData.minutes) >= currentMinutes,
      };
    });
  }, [utcData, date]);

  return (
    <Stack
      flexDirection={'row'}
      sx={{
        width: '500px',
        height: '310px',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
        padding: '15px 0',
        backgroundColor: white,
      }}
    >
      <Box
        sx={{
          borderRight: '3px solid #E8EBF5',
          width: '340px',
          height: '100%',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={date ? dayjs(date, 'YYYY-MM-DD') : null}
            onChange={handleDateChange}
            views={['month', 'day']}
            minDate={dayjs(dates?.currentDate)}
            // maxDate={dayjs(dates.futureDate)}
            disableHighlightToday
            showDaysOutsideCurrentMonth
            slotProps={{
              calendarHeader: {
                sx: {
                  mt: 0,
                  button: {
                    color: dusk,
                  },
                },
              },
              day: {
                sx: {
                  fontWeight: 'bold',
                  color: midnight,
                  '&.Mui-selected': {
                    backgroundColor: `${newBlueglass} !important`,
                    color: 'white',
                    borderRadius: '0',
                  },
                },
              },
            }}
            sx={{
              height: '290px',
              // Target the month/year display (header section)
              '.MuiPickersCalendarHeader-root': {
                fontWeight: 'bold',
                fontSize: '16px',
              },
              // Target the day-of-week labels (S, M, T...)
              '.MuiPickersCalendarHeader-labelContainer': {
                fontWeight: 'bold',
                fontSize: '14px',
              },
              // Target the month buttons in month view
              '.MuiPickersMonth-monthButton': {
                fontWeight: 'bold',
                fontSize: '16px',
              },
              // Ensure the border-radius is 0 for selected month
              '.MuiPickersMonth-monthButton.Mui-selected': {
                backgroundColor: `${newBlueglass} !important`,
                color: 'white',
                borderRadius: '0',
              },
              // Target the year selection dropdown
              '.MuiPickersYear-yearButton': {
                fontWeight: 'bold',
                fontSize: '16px',
              },
              // Selected year styling
              '.MuiPickersYear-yearButton.Mui-selected': {
                backgroundColor: `${newBlueglass} !important`,
                color: 'white',
                borderRadius: 0, 
              },
              '.Mui-selected': {
                backgroundColor: `${newBlueglass} !important`,
                color: 'white',
              },
            }}
          />
        </LocalizationProvider>
      </Box>
      <Stack sx={{ height: '100%', width: '165px' }} alignItems={'center'}>
        <Box pt={1.4}>
          <Typography sx={{ fontWeight: 700, fontSize: '18px' }}>
            UTC
          </Typography>
        </Box>
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          sx={{ height: '100% ' }}
          gap={1}
        >
          <Stack sx={{ width: '45px' }} gap={1}>
            <PrimaryButton
              buttonTitle=""
              startIcon={
                <ArrowDown
                  style={{
                    transform: 'rotate(180deg)',
                    fill: btnDisable?.hoursIncrease ? disabledIcon : dusk,
                  }}
                />
              }
              customSx={btnStyles}
              onClick={() => {
                handleUpdate('hours', 'increment');
              }}
              disabled={btnDisable?.hoursIncrease}
            />
            <InputField
              label=""
              customSx={{
                width: '45px',
                height: '45px',
                border: `1px solid ${heather}`,
                borderRadius: '4px',
                padding: '1px 1px',
                my: 0,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none !important',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none !important',
                  },
                },

                '& .MuiOutlinedInput-input': {
                  padding: '9px 9px',
                  backgroundColor: white,
                  textAlign: 'center',
                },
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: midnight,
                },
              }}
              value={utcData?.hours !== null ? utcData?.hours : ''}
              onChange={(e) => {
                const value = (e.target as HTMLInputElement)?.value;
                const numericValue = parseInt(value);
                if (
                  !isNaN(numericValue) &&
                  numericValue >= 0 &&
                  numericValue <= 23
                ) {
                  setUtcData((prev) => ({
                    ...prev,
                    hours: value,
                  }));
                } else if (value === '') {
                  setUtcData((prev) => ({
                    ...prev,
                    hours: '',
                  }));
                }
              }}
              inputAttrProps={{
                maxLength: 2,
              }}
            />
            <PrimaryButton
              buttonTitle=""
              startIcon={
                <ArrowDown
                  style={{
                    fill: btnDisable?.hoursDecrease ? disabledIcon : dusk,
                  }}
                />
              }
              customSx={btnStyles}
              onClick={() => {
                handleUpdate('hours', 'decrement');
              }}
              disabled={btnDisable?.hoursDecrease}
            />
          </Stack>

          <Stack>
            <Typography sx={{ color: midnight, fontSize: '22px' }}>
              :
            </Typography>
          </Stack>

          <Stack sx={{ width: '45px' }} gap={1}>
            <PrimaryButton
              buttonTitle=""
              startIcon={
                <ArrowDown
                  style={{
                    transform: 'rotate(180deg)',
                    fill: btnDisable?.minutesIncrease ? disabledIcon : dusk,
                  }}
                />
              }
              customSx={btnStyles}
              onClick={() => {
                handleUpdate('minutes', 'increment');
              }}
              disabled={btnDisable?.minutesIncrease}
            />

            <InputField
              label=""
              customSx={{
                width: '45px',
                height: '45px',
                border: `1px solid ${heather}`,
                borderRadius: '4px',
                padding: '1px 1px',
                my: 0,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none !important',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none !important',
                  },
                },
                '& .MuiOutlinedInput-input': {
                  padding: '9px 9px',
                  background: 'white',
                  textAlign: 'center',
                },
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: midnight,
                },
              }}
              value={utcData?.minutes !== null ? utcData?.minutes : ''}
              onChange={(e) => {
                const value = (e.target as HTMLInputElement)?.value;
                const numericValue = parseInt(value);
                if (
                  !isNaN(numericValue) &&
                  numericValue >= 0 &&
                  numericValue <= 59
                ) {
                  setUtcData((prev) => ({
                    ...prev,
                    minutes: value,
                  }));
                } else if (value === '') {
                  setUtcData((prev) => ({
                    ...prev,
                    minutes: '',
                  }));
                }
              }}
              inputAttrProps={{ maxLength: 2 }}
            />
            <PrimaryButton
              buttonTitle=""
              startIcon={
                <ArrowDown
                  style={{
                    fill: btnDisable?.minutesDecrease ? disabledIcon : dusk,
                  }}
                />
              }
              customSx={btnStyles}
              onClick={() => {
                handleUpdate('minutes', 'decrement');
              }}
              disabled={btnDisable?.minutesDecrease}
            />
          </Stack>
        </Stack>
        {onSubmit && (
          <PrimaryButton
            buttonTitle="Save"
            customSx={{
              mt: 2,
              width: '70%',
              backgroundColor: newBlueglass,
              color: white,
            }}
            onClick={() => {
              const hours = utcData.hours.toString().padStart(2, '0');
              const minutes = utcData.minutes.toString().padStart(2, '0');
              const selectedUtcTime = `${date}T${hours}:${minutes}:00`;
              onSubmit(selectedUtcTime);
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default CustomDatePicker;
