import {
  Checkbox,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  Alert,
  Popover,
  Button,
  CircularProgress, // Import Button for dropdown-like behavior
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { cloud, dusk, eggshell, midnight, white } from 'src/constants/colors';
import { Footer } from '@components/footer';
import { CustomFusionEventData } from 'src/pages/AlertManager/UIConfigTab';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import {
  FusionEventCategory,
  useCreateOpenRegMutation,
} from 'src/services/gql/generated';
import { useGetFusionTopicsData } from './TopicsDataContext';
import { GetOpenUserRegistrationsQuery } from 'src/services/gql/queries/GetOpenUserRegistrationsQuery.gql';
import CustomDatePicker from '@components/input/CustomDatePicker';
import { InputWithLabel } from '@components/input/InputWithLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NotifiTooltip } from '@components/NotifiTooltip';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; 
dayjs.extend(utc);

type Props = {
  handleClose: () => void;
  open: boolean;
  isEdit?: boolean;
  topic?: CustomFusionEventData;
  isEditNonNotifiHosted?: boolean;
};

const CreateEasySubscriptionUICardModal = ({ handleClose, open }: Props) => {
  const [nameConfig, setNameConfig] = React.useState('');
  const [startDate, setStartDate] = React.useState(
    dayjs().utc().format('YYYY-MM-DDTHH:mm:ss'),
  );
  const [endDate, setEndDate] = React.useState('');
  const { items: topicsData, isLoading } = useGetFusionTopicsData();
  const [selectedTopicIds, setSelectedTopicIds] = React.useState<string[]>([]);

  const [createOpenRegMutation, { loading: createOpenRegLoading }] =
    useCreateOpenRegMutation();

  const [anchorElStart, setAnchorElStart] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElEnd, setAnchorElEnd] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenStartPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElStart(event.currentTarget);
  };

  const handleCloseStartPopover = () => {
    setAnchorElStart(null);
  };

  const handleOpenEndPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElEnd(event.currentTarget);
  };

  const handleCloseEndPopover = () => {
    setAnchorElEnd(null);
  };

  const isStartPopoverOpen = Boolean(anchorElStart);
  const isEndPopoverOpen = Boolean(anchorElEnd);
  const formatedStartDate = dayjs(startDate).format(
    'HH:mm [UTC on] MMM D, YYYY',
  );
  const formatedEndDate = dayjs(endDate).format('HH:mm [UTC on] MMM D, YYYY');

  const [errors, setErrors] = React.useState({
    nameConfig: '',
    startDate: '',
    endDate: '',
    selectedTopicIds: '',
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      nameConfig: '',
      startDate: '',
      endDate: '',
      selectedTopicIds: '',
    };

    if (!nameConfig.trim()) {
      newErrors.nameConfig = 'Name config is required.';
      valid = false;
    }

    if (!startDate.trim()) {
      newErrors.startDate = 'Start Date is required.';
      valid = false;
    }

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      if (start >= end) {
        newErrors.endDate = 'End Date must be greater than Start Date.';
        valid = false;
      }
    }

    if (!selectedTopicIds.length) {
      newErrors.selectedTopicIds = 'At least one topic must be selected.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const payload: {
        beginTime: string;
        fusionEventIds: string[];
        name: string;
        endTime?: string;
      } = {
        beginTime: startDate,
        fusionEventIds: selectedTopicIds,
        name: nameConfig,
      };
      if (endDate) {
        payload.endTime = endDate;
      }
      await createOpenRegMutation({
        variables: {
          input: payload,
        },
        refetchQueries: [{ query: GetOpenUserRegistrationsQuery }],
      });
      handleClose();
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleTopicChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedTopicIds(event.target.value as string[]);
  };

  const isFormValid =
    !!nameConfig.trim() && !!startDate.trim() && selectedTopicIds.length > 0;

  return (
    <div>
      <Dialog
        fullScreen
        onClose={handleClose}
        open={open}
        sx={{
          '&.MuiPaper-root &.MuiDialog-paper': {
            backgroundColor: 'transparent',
          },
          backgroundColor: '#F3F3FA',
        }}
      >
        <DialogContent
          sx={{
            backgroundColor: '#F3F3FA',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 0,
          }}
        >
          <Stack width={'100%'} mt={6} mb={5}>
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              width="100%"
              gap="20px"
            >
              <IconButton
                sx={{
                  flexShrink: 0,
                  backgroundColor: white,
                  borderRadius: '45px',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: white,
                  },
                  visibility: 'hidden',
                }}
              >
                <ArrowBackIcon sx={{ color: dusk }} />
              </IconButton>

              <Stack
                alignItems="center"
                justifyContent="center"
                flexGrow="1"
                flexShrink="1"
              >
                <Typography variant="h1">Create a new UI Config</Typography>
              </Stack>

              <IconButton
                sx={{
                  flexShrink: 0,
                  backgroundColor: white,
                  borderRadius: '45px',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: white,
                  },
                }}
                onClick={handleClose}
              >
                <CloseIcon sx={{ color: dusk }} />
              </IconButton>
            </Stack>

            <Stack alignItems="center" justifyContent="center" flexGrow="1">
              <Typography variant="h2">Easy Subscribe</Typography>
            </Stack>
          </Stack>

          <Stack
            flexDirection="column"
            justifyContent="center"
            sx={{
              backgroundColor: white,
              borderRadius: '8px',
              mb: 3,
              p: 4,
            }}
          >
            {errors.nameConfig && (
              <Alert severity="error" sx={{ width: '360px', mb: 2 }}>
                {errors.nameConfig}
              </Alert>
            )}

            <InputWithLabel
              value={nameConfig}
              handleChange={(e) => setNameConfig(e.target.value)}
              label="Name Config"
              customSx={{ width: '360px', mb: 3 }}
            />
            <Stack direction={'row'} alignItems={'center'} sx={{ mb: 3 }}>
              <Typography color={midnight} fontSize={'16px'}>
                When should this widget be enabled?
              </Typography>
              <NotifiTooltip
                tooltipContent={
                  'You can add a start and end time to your Easy Subscribe entry point. If you only want to allow users to subscribe for a limited time you can add an end date.'
                }
                buttonSx={{ ml: '4px' }}
                tooltipSx={{ color: dusk }}
                boxSx={{
                  pl: '20px',
                  borderRadius: '8px',
                  border: `1px solid ${cloud}`,
                }}
              />
            </Stack>

            <Button
              variant="outlined"
              onClick={handleOpenStartPopover}
              sx={{
                width: '360px',
                mb: 3,
                justifyContent: 'space-between',
                fontSize: '14px',
                fontWeight: 700,
                color: startDate ? midnight : dusk,
                pl: '20px',
                py: 3.5,
                borderRadius: '5px',
                border: `1px solid ${cloud}`,
                backgroundColor: cloud,
                textTransform: 'none',
              }}
              endIcon={<ExpandMoreIcon />}
            >
              {startDate
                ? startDate
                  ? formatedStartDate
                  : 'Select Start Date & Time'
                : 'Select Start Date & Time'}
            </Button>

            <Popover
              id="start-date-picker"
              open={isStartPopoverOpen}
              anchorEl={anchorElStart}
              onClose={handleCloseStartPopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  borderRadius: 0,
                  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <CustomDatePicker
                selectedDate={startDate}
                onSubmit={(dateTime) => {
                  setStartDate(dateTime);
                  setAnchorElStart(null);
                }}
              />
            </Popover>

            <Button
              variant="outlined"
              onClick={handleOpenEndPopover}
              sx={{
                width: '360px',
                mb: 3,
                justifyContent: 'space-between',
                fontSize: '14px',
                fontWeight: 700,
                color: endDate ? midnight : dusk,
                pl: '20px',
                py: 3.5,
                borderRadius: '5px',
                border: `1px solid ${cloud}`,
                backgroundColor: cloud,
                textTransform: 'none',
              }}
              endIcon={<ExpandMoreIcon />}
            >
              {endDate ? formatedEndDate : 'Select End Date & Time (Optional)'}
            </Button>

            <Popover
              id="end-date-picker"
              open={isEndPopoverOpen}
              anchorEl={anchorElEnd}
              onClose={handleCloseEndPopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  borderRadius: 0,
                  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <CustomDatePicker
                selectedDate={endDate}
                onSubmit={(dateTime) => {
                  setEndDate(dateTime);
                  setAnchorElEnd(null);
                }}
              />
            </Popover>

            {!!errors.endDate && (
              <Typography
                variant="caption"
                color="error"
                sx={{ mt: -2, mb: 2 }}
              >
                {errors.endDate}
              </Typography>
            )}

            <Typography
              sx={{ mb: 3, width: '360px' }}
              color={midnight}
              fontSize={'16px'}
            >
              Select Community Manager topics users can subscribe to:
            </Typography>

            <FormControl
              sx={{ width: '360px', mb: '12px' }}
              error={!!errors.selectedTopicIds}
            >
              <InputLabel>Select Topic</InputLabel>
              <Select
                multiple
                value={selectedTopicIds}
                onChange={handleTopicChange}
                renderValue={(selected) =>
                  (selected as string[])
                    .map(
                      (id) => topicsData.find((topic) => topic.id === id)?.name,
                    )
                    .join(', ')
                }
                IconComponent={ExpandMoreIcon}
              >
                {isLoading && (
                  <MenuItem
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    disabled
                  >
                    <CircularProgress
                      style={{ alignSelf: 'center' }}
                      size={24}
                    />
                  </MenuItem>
                )}
                {topicsData
                  .filter(
                    (item) =>
                      item.fusionEventCategory ===
                      FusionEventCategory.COMMUNITY_MANAGER,
                  )
                  .map((topic) => (
                    <MenuItem key={topic.id} value={topic.id}>
                      <Checkbox
                        checked={selectedTopicIds.indexOf(topic.id) > -1}
                      />
                      <ListItemText primary={topic.name} />
                    </MenuItem>
                  ))}
              </Select>
              {!!errors.selectedTopicIds && (
                <Typography variant="caption" color="error">
                  {errors.selectedTopicIds}
                </Typography>
              )}
            </FormControl>
          </Stack>

          <PrimaryButton
            customSx={{ width: '156px', mb: 4 }}
            disabled={!isFormValid || createOpenRegLoading}
            buttonTitle="Save Config"
            onClick={handleSubmit}
          />
          <Footer sx={{ background: eggshell }} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateEasySubscriptionUICardModal;
