import { SecondaryButton } from '@components/buttons/SecondaryButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  dusk,
  eggshell,
  midnight,
  white,
  midnightLight,
} from 'src/constants/colors';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';

type Destination =
  | 'email'
  | 'telegram'
  | 'discord'
  | 'SMS'
  | 'alertHistory'
  | 'slack'
  | 'walletAlert'
  | 'browser';

type Props = {
  handleClose?: () => void;
  loading?: boolean;
  open: boolean;
  template: string;
  destination: Destination;
  isViewPage?: boolean;
  isInsertHtmlContent?: boolean;
};

const PreviewDialog: React.FC<React.PropsWithChildren<Props>> = ({
  template,
  handleClose,
  open,
  destination,
  isViewPage = false,
  isInsertHtmlContent = true,
}) => {
  const { isAP2Enabled } = useFeatureFlag();
  let htmlContent = '';
  if (isInsertHtmlContent) {
    if (
      destination === 'SMS' ||
      destination === 'walletAlert' ||
      destination === 'telegram' ||
      destination === 'discord' ||
      destination === 'slack'
    ) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(template, 'text/html');
      htmlContent = doc.documentElement.innerHTML;
    } else {
      htmlContent = template;
    }
  }
  const replaceLinks = (html: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const links = doc.getElementsByTagName('a');

    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      link.setAttribute('target', '_blank');
    }

    return doc.documentElement.innerHTML;
  };

  const previewId = () => {
    switch (destination) {
      case 'email':
        return 'email-preview-container';
      case 'telegram':
        return 'telegram-preview-container';
      case 'SMS':
      case 'walletAlert':
        return 'SMS-preview-container';
      case 'discord':
        return 'discord-preview-container';
      case 'alertHistory':
        return 'alert-history-preview-container';
      case 'slack':
        return 'telegram-preview-container';
      case 'browser':
        return 'browser-preview-container';
    }
  };

  return (
    <Dialog
      fullWidth={false}
      maxWidth="md"
      onClose={handleClose}
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: isAP2Enabled ? '4px' : '15px',
          maxHeight: '90%',
          width: '100%',
          maxWidth: '683px',
        },
      }}
    >
      <Stack
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        sx={{
          backgroundColor: isAP2Enabled ? white : midnightLight,
          fontFamily: 'Rota',
          py: 4,
          width: '100%',
        }}
      >
        <DialogTitle
          sx={{
            color: eggshell,
            fontSize: '26px',
            fontWeight: 700,
            p: 0,
            textAlign: 'center',
            width: '100%',
          }}
        >
          <Box alignItems="center" display="flex">
            <Typography
              flexGrow={1}
              sx={{
                px: '50px',
                fontSize: isAP2Enabled ? '20px' : '26px',
                pb: isAP2Enabled ? '28px' : 1,
                lineHeight: 'normal',
              }}
            >
              Preview
            </Typography>
            <Box
              sx={{
                position: 'absolute',
                right: '20px',
                top: '20px',
              }}
            >
              <IconButton
                onClick={handleClose}
                sx={{ color: isAP2Enabled ? dusk : white }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            maxHeight: '400px',
            overflow: 'scroll',
            width: '100%',
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <DialogContent
            sx={{
              maxHeight: '385px',
              overflow: 'unset',
              py: 0,
              px: 0,
              color: midnight,
            }}
          >
            {isInsertHtmlContent ? (
              <div
                dangerouslySetInnerHTML={{ __html: replaceLinks(htmlContent) }}
                className="preview-container"
                id={previewId()}
              />
            ) : (
              <div className="preview-container" id={previewId()}>
                {template}
              </div>
            )}
          </DialogContent>
        </Stack>
        <Stack
          alignItems="center"
          direction={'row'}
          gap={3}
          justifyContent="center"
          sx={{ mt: 4 }}
        >
          {!isViewPage ? (
            isAP2Enabled ? (
              <PrimaryButton
                onClick={handleClose}
                customSx={{
                  width: '164px',
                  boxShadow: 'none',
                }}
                buttonTitle="Continue Editing"
              />
            ) : (
              <SecondaryButton
                onClick={handleClose}
                customSx={{
                  width: '164px',
                }}
                buttonTitle="Continue Editing"
              />
            )
          ) : null}
          {}
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default PreviewDialog;
